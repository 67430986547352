import { createRouter, createWebHistory } from 'vue-router'
function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}
function lazyPage(view){
  return() => import(`@/views/page/${view}.vue`)
}
function lazyUser(view){
  return() => import(`@/views/User/${view}.vue`)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyLoad('Home')
  },
  {
    path: '/trailer',
    name: 'Trailer',
    component: lazyPage('Trailer')
  },
  {
    path: '/content/:content_id',
    name: 'Content',
    component: lazyPage('Content'),
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: lazyUser('Login')
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: lazyUser('Unsubscribe')
  },
  {
    path: '/category/:id',
    name: 'Category',
    component: () => import(/* webpackChunkName: "ab3adHealthy" */ '../views/Category/categorys.vue'),
    props:true
  },
  {
    path: '/search/:search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "ab3adHealthy" */ '../views/page/Searches.vue'),
    props:true
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: lazyUser('Subscribe')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
