import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import lazyPlugin from 'vue3-lazy'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { VueCookieNext } from 'vue-cookie-next'
const loadimage = require('./assets/loader.gif')

createApp(App)
    .use(store)
    .use(router)
    .use(lazyPlugin, {
        preLoad: 1.3,
        loading: loadimage,
        attempt: 1
    })
    .use(Toast, {
        // Setting the global default position
        position: POSITION.BOTTOM_RIGHT
    })
    .use(VueCookieNext)
    .mount('#app')
